
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands, getTestPort } from "@/utils/common";
import { Pageniation, Loading } from "@/utils/decorator";
import { formDownload } from "../../utils/tools";
import dayjs from "dayjs";

const service = namespace("service");
const base = namespace("base");
const management = namespace("management");

@Component({})
@Pageniation("loadListData")
export default class ServiceList extends Vue {
  dayjs = dayjs;
  @service.State currentTabType;
  @base.Action getAreaList;
  @service.Action getNewOrderList;
  @service.Action getOrderList;
  @management.Action getStoreList;
  @management.Action queryAllChannel;
  @base.Action getDictionaryList;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  getTestPort = getTestPort;
  orderList: any[] = [];
  searchForm = {
    orderTimeEnd: "",
    orderTimeStart: "",
    orderStatusList: [],
    searchKey: "",
    settlementStatus: "",
    technicianName: "",
    invoicingRequire: "", // 是否需要开票
    reservationArrivalTimeEnd: "",
    reservationArrivalTimeStart: "",
    orderSource: "", // 订单来源
    channelOrderNo: "", // 渠道订单号
    externalOrderNo: "", // 外部订单号
    storeCode: "", // 门店
    stateName: "",
    cityName: "",
    districtName: "",
    storeType: ""
  };
  get breadData() {
    return [
      { name: this.$t("test.service-management"), path: "/service" },
      {
        name:
          this.$route.name === "partsAdd" ? this.$t("service.new-maintenance") : this.$t("setting.editing-spare-parts")
      }
    ];
  }
  get tabBarData() {
    return [{ tabName: this.$t("service.all-services"), type: "all" }];
  }
  orderForm: any = [
    {
      item: "",
      type: ""
    }
  ];
  orderStatusList: any[] = [];
  settlementList: any[] = [];
  orderSourceList: any[] = [];
  appointmentDate: any = [];
  handleSort({ prop, order }) {
    this.mix_pageNum = 1;
    this.orderForm = [
      {
        item: order ? prop : "",
        type: order ? (order === "ascending" ? "asc" : "desc") : ""
      }
    ];
    this.loadListData();
  }
  getComplexOrderStatusStr(row) {
    if (row.orderStatus === "4" || row.orderStatus === "9") {
      return row.orderStatusDesc;
    } else {
      return row.channelOrderStatusDesc || row.orderStatusDesc;
    }
  }
  getComplexOrderTypeStr(row) {
    if (row.orderSource === "10" || row.orderSource === "11" || row.orderSource === "12") {
      return row.orderSourceDesc;
    } else {
      return row.orderTypeDesc;
    }
  }
  created() {
    this.init();
  }
  handleExportData() {
    const _env = getTestPort();
    formDownload(`${_env}/opOrder/exportOpOrder`, this.searchForm);
  }
  storeTypeList: any = [];
  init() {
    this.loadListData();
    // 结算方式字典List
    this.getDictionaryList("settlement_status").then(data => {
      this.settlementList = data;
    });
    // 订单状态字典List
    this.getDictionaryList("order_status").then(data => {
      this.orderStatusList = data || [];
    });
    // 订单来源List
    // this.getDictionaryList("order_source").then(data => {
    //   this.orderSourceList = data;
    // });
    this.queryAllChannel().then(data => {
      this.orderSourceList = data.data.map(item => {
        return {
          label: item.channelName,
          value: item.sourceCode
        };
      });
    });
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    this.searchStore("");
  }
  @Loading()
  loadListData() {
    return this.getOrderList({
      ...this.searchForm,
      orderItems: this.orderForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.orderList = data.data.list;
      return data;
    });
  }

  storeList: any = [];
  seletedStatus: string = "";
  get searchList() {
    return [
      {
        label: this.$t("test.keyword"),
        type: "input",
        value: "",
        prop: "searchKey",
        placeholder: this.$t("test.license-name-mobile-vincode")
      },
      {
        label: this.$t("v210831.store-type"),
        type: "select",
        value: "",
        selectList: this.storeTypeList,
        prop: "storeType"
      },
      {
        label: this.$t("staff.store"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: this.$t("base.order-status"),
        type: "select",
        selectList: this.orderStatusList,
        value: this.seletedStatus,
        prop: "orderStatusList"
      },
      {
        label: this.$t("base.settlement-status"),
        type: "select",
        // multiple: true,
        selectList: this.settlementList,
        value: "",
        prop: "settlementStatus"
      },
      {
        label: this.$t("setting.region"),
        type: "area",
        value: "",
        labels: [],
        prop: "area",
        anyLevel: true
      },
      {
        label: this.$t("service.need-invoice"),
        type: "select",
        selectList: [
          {
            label: this.$t("v210801.yes"),
            value: "1"
          },
          {
            label: this.$t("v210801.no"),
            value: "0"
          }
        ],
        value: "",
        prop: "invoicingRequire"
      },
      {
        label: this.$t("v210801.creation-time"),
        type: "daterange",
        value: "",
        prop: "createDate"
      },
      {
        label: this.$t("service.date-reservation"),
        type: "daterange",
        value: this.appointmentDate,
        prop: "reservationArrivalTime"
      },
      {
        label: this.$t("dashboard.channel-for"),
        type: "select",
        selectList: this.orderSourceList,
        value: "",
        prop: "orderSource"
      },
      {
        label: this.$t("service.channel-order-number"),
        type: "input",
        value: "",
        prop: "channelOrderNo",
        placeholder: ""
      },
      {
        label: this.$t("service.external-order-number"),
        type: "input",
        value: "",
        prop: "externalOrderNo",
        placeholder: ""
      },
      {
        label: this.$t("finance.receipt-number"),
        type: "input",
        value: "",
        prop: "orderNo",
        placeholder: ""
      },

    ];
  }
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "createDate") {
          this.searchForm["orderTimeStart"] = item.value[0] || "";
          this.searchForm["orderTimeEnd"] = item.value[1] || "";
        } else if (item.prop === "reservationArrivalTime") {
          this.searchForm["reservationArrivalTimeStart"] = item.value[0] || "";
          this.searchForm["reservationArrivalTimeEnd"] = item.value[1] || "";
        } else if (item.prop === "orderStatusList") {
          this.searchForm[item.prop] = item.value ? [item.value] : [];
        } else if (item.prop === "area") {
          this.searchForm.stateName = item.labels[0] || "";
          this.searchForm.cityName = item.labels[1] || "";
          this.searchForm.districtName = item.labels[2] || "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }

  handleView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.orderNo}?tenantCode=${row.tenantCode}`
    });
    window.open(href, "_blank");
  }

  handleUserView(row) {
    let { href } = this.$router.resolve({
      path: `/customer/customer-view/${row.customerMobile}`
    });
    window.open(href, "_blank");
  }
}
